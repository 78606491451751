import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
import CountUp from 'react-countup';
import '../styles/index.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const Heading = makeShortcode("Heading");
const Box = makeShortcode("Box");
const Level = makeShortcode("Level");
const LevelItem = makeShortcode("LevelItem");
const Columns = makeShortcode("Columns");
const Column = makeShortcode("Column");
const ContractsForm = makeShortcode("ContractsForm");
const StaticMapSuppliers = makeShortcode("StaticMapSuppliers");
const ProductPriceEstimationForm = makeShortcode("ProductPriceEstimationForm");
const StaticMapBuyers = makeShortcode("StaticMapBuyers");
const Content = makeShortcode("Content");
const VideosSection = makeShortcode("VideosSection");
const ServicesSection = makeShortcode("ServicesSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section id='head' size='medium' textAlign='center' backgroundColor='light' style={{
      position: 'relative',
      backgroundColor: '#f8fff8',
      //backgroundImage: 'url(/background.png)',
      //backgroundSize: 'cover',
      //backgroundPosition: '50% 30%',
      //padding: '5em',
      //backgroundRepeat: 'repeat',
      paddingBottom: '3em'
    }} mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} align='center' mdxType="Heading">
      La <strong className='highlighted'>Marketplace</strong> Enia
    </Heading>
    <hr />
    <Box my={6} mdxType="Box">
      <Level mdxType="Level">
        <LevelItem mdxType="LevelItem">
          <Heading renderAs='p' size={5} subtitle textAlign='center' mdxType="Heading">
            <Heading renderAs='span' size={3} mdxType="Heading">
              <strong>
                <CountUp duration={2} separator=' ' start={0} end={18000} mdxType="CountUp" />
              </strong>
            </Heading>
            <br />
            fournisseurs
            <Container mobile={{
                  display: 'hidden'
                }} mdxType="Container">
              <small>&nbsp;</small>
            </Container>
          </Heading>
        </LevelItem>
        <LevelItem mdxType="LevelItem">
          <Heading renderAs='p' size={5} subtitle textAlign='center' mdxType="Heading">
            <Heading renderAs='span' size={3} mdxType="Heading">
              <strong>
                <CountUp duration={2} separator=' ' start={0} end={800} mdxType="CountUp" />
              </strong>
            </Heading>
            <br />
            gisements
            <br />
            <small>ultra qualifiés</small>
          </Heading>
        </LevelItem>
        <LevelItem mdxType="LevelItem">
          <Heading renderAs='p' size={5} subtitle textAlign='center' mdxType="Heading">
            <Heading renderAs='span' size={3} mdxType="Heading">
              <strong>
                <CountUp duration={2} separator=' ' start={0} end={250} mdxType="CountUp" />
              </strong>
            </Heading>
            <br />
            acheteurs
            <br />
            <small>sur la plateforme</small>
          </Heading>
        </LevelItem>
        <LevelItem mdxType="LevelItem">
          <Heading renderAs='p' size={5} subtitle textAlign='center' mdxType="Heading">
            <Heading renderAs='span' size={3} mdxType="Heading">
              <strong>
                <CountUp duration={2} separator=' ' start={0} end={500000} mdxType="CountUp" />
              </strong>
            </Heading>
            <br />
            tonnes de substrat
            <br />
            <small>en gestion</small>
          </Heading>
        </LevelItem>
        <LevelItem mdxType="LevelItem">
          <Heading renderAs='p' size={5} subtitle textAlign='center' mdxType="Heading">
            <Heading renderAs='span' size={3} mdxType="Heading">
              <strong>
                <CountUp duration={2} separator=' ' start={0} end={57000} mdxType="CountUp" />
              </strong>
            </Heading>
            <br />
            tonnes de <abbr title='Dioxyde de carbone'>CO₂</abbr>
            <br />
            <small>sauvées</small>
          </Heading>
        </LevelItem>
      </Level>
    </Box>
  </Container>
    </Section>
    <Section id='marketplace' size='medium' mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h3' size={4} align='center' mdxType="Heading">
      Vous recherchez des matières organiques pour votre unité de méthanisation ?
    </Heading>
    <Container className='presentation' my={6} mdxType="Container">
      <Box mdxType="Box">
        <Columns mdxType="Columns">
          <Column size={6} mdxType="Column">
            <br />
            <Heading renderAs='h3' size={3} mdxType="Heading">
              Achetez des matières organiques
            </Heading>
            <ContractsForm mdxType="ContractsForm" />
          </Column>
          <Column size={6} display='relative' style={{
                position: 'relative !important'
              }} mobile={{
                display: 'hidden'
              }} mdxType="Column">
            <StaticMapSuppliers geolocation mdxType="StaticMapSuppliers" />
          </Column>
        </Columns>
      </Box>
    </Container>
    <hr />
    <Heading renderAs='h3' size={4} align='center' mdxType="Heading">
      Vous possédez de la matière organique et souhaitez la valoriser en méthanisation ?
    </Heading>
    <Container className='presentation' my={6} mdxType="Container">
      <Box mdxType="Box">
        <Columns mdxType="Columns">
          <Column size={6} mdxType="Column">
            <br />
            <Heading renderAs='h3' size={3} mobile={{
                  size: 4
                }} mdxType="Heading">
              Mettez votre matière en vente
            </Heading>
            <ProductPriceEstimationForm mdxType="ProductPriceEstimationForm" />
          </Column>
          <Column size={6} display='relative' style={{
                position: 'relative !important'
              }} mobile={{
                display: 'hidden'
              }} mdxType="Column">
            <StaticMapBuyers geolocation mdxType="StaticMapBuyers" />
          </Column>
        </Columns>
      </Box>
    </Container>
  </Container>
    </Section>
    <Section id='presentation' size='medium' backgroundColor='light' textAlign='center' mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} mdxType="Heading">
      <strong className='highlighted'>Présentation</strong> de la Marketplace
    </Heading>
    <hr />
    <Content size='medium' textAlign='start' mdxType="Content">
      <blockquote>
        <p>
          En tant que producteur de biogaz, la marketplace permet <strong>d'identifier</strong> des gisements de matière
          organiques de votre zone.
        </p>
        <p>
          En tant que producteur de matières organiques, elle permet de trouver l'unité de méthanisation qui{' '}
          <strong>valorisera</strong> vos matières.
        </p>
        <p>
          Grâce à Enia accédez à un <strong>catalogue de services</strong> de transport, d'analyses, de conseil,
          d'épendage, de financement ou autres ... Enia travaille à l'échelle nationale avec ses partenaires et obtient
          des tarifs <strong>imbattables</strong> !
        </p>
      </blockquote>
    </Content>
  </Container>
  <hr />
  <VideosSection mdxType="VideosSection" />
    </Section>
    <ServicesSection mdxType="ServicesSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      